import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState, useRef } from 'react';

import { Burger } from './burger';
import { ContactBtns } from './contactBtns';
import { MeetingModal } from './meetingModal';
import { MobileMenu } from './mobileMenu';
import { NavSubmenu } from './navSubmenu';
import { ReactComponent as SVGLogo } from '@/assets/svg/logo.svg';
import { useHistory } from '@/utils';
import { servicesLinks, aboutUsLinks } from '@/utils/staticData';

import './index.scss';

export const Header = () => {
  const [fixedHeader, setFixedHeader] = useState(false);
  const [animatedHeader, setAnimatedHeader] = useState(false);
  const [burgerActive, setBurgerActive] = useState(false);

  const { isPageWithoutIntro } = useHistory();

  const { asPath: currentUrl } = useRouter();

  const calculateFixedHeader = () => {
    if (window.scrollY >= 200) {
      setFixedHeader(true);
      setAnimatedHeader(true);
    } else {
      setFixedHeader(false);
      setAnimatedHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', calculateFixedHeader);

    return () => {
      window.removeEventListener('scroll', calculateFixedHeader);
    };
  });

  useEffect(() => calculateFixedHeader(), [currentUrl]);

  const toggleMobileMenuOpen = useMemo(() => () => setBurgerActive((v) => !v), []);

  const wasBurgerActive = useRef(burgerActive);

  useEffect(() => {
    wasBurgerActive.current = burgerActive;
  });

  useEffect(() => {
    if (wasBurgerActive.current) setFixedHeader(true);
  }, [burgerActive]);

  return (
    <>
      <header
        className={cx('header', {
          'header--fixed': !!fixedHeader || burgerActive,
          'header--background': !!fixedHeader || !!isPageWithoutIntro,
          'header--animated': !!animatedHeader
        })}
      >
        <div className="header__inner container">
          <Link className="header__logo" href="/" aria-label="UUUSoftware">
            <SVGLogo />
          </Link>
          <Burger burgerActive={burgerActive} toggleBurgerMenu={toggleMobileMenuOpen} />
          <nav className="nav">
            <ul className="nav__list">
              <NavSubmenu linksList={aboutUsLinks} baseTitle="Company" />
              <NavSubmenu linksList={servicesLinks} baseTitle="Services" baseLink="/services" />
              <li className="nav__item">
                <Link href="/industries" className="nav__link">
                  Industries
                </Link>
              </li>
              <li className="nav__item">
                <Link href="/case-studies" className="nav__link">
                  Case studies
                </Link>
              </li>
              <li className="nav__item">
                <Link href="/blog" className="nav__link">
                  Blog
                </Link>
              </li>
              <ContactBtns />
            </ul>
          </nav>

          <MobileMenu
            isOpen={burgerActive}
            toggleIsOpen={toggleMobileMenuOpen}
            aboutUsLinks={aboutUsLinks}
            servicesLinks={servicesLinks}
          />
        </div>
      </header>
      <MeetingModal />
    </>
  );
};
