import { LinkProps } from 'next/link';

export const meetingCalendarPersons = {
  '#book-meeting': 'https://meetings.hubspot.com/andrey-alexandrov',
  '#book-meeting-with-robert-arabajyan': 'https://meetings.hubspot.com/robert-arabajyan'
};

export const servicesLinks: {
  id: string;
  title: string;
  url: LinkProps['href'];
  link: LinkProps['href'];
}[] = [
  {
    id: 'staffing',
    title: 'Onshore & Offshore Staffing',
    url: '/services/onshore-and-offshore-staffing',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'onshore-and-offshore-staffing' }
    }
  },
  {
    id: 'devops',
    title: 'DevOps & Cloud Migration',
    url: '/services/cloud-migrations-and-devops',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'devops-and-cloud-migration' }
    }
  },
  {
    id: 'web dev',
    title: 'Web Development',
    url: '/services/web-development',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'web-development' }
    }
  },
  {
    id: 'reactjs',
    title: 'ReactJS Development',
    url: '/services/custom-react-development',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'custom-react-development' }
    }
  },
  {
    id: 'mobile dev',
    title: 'Mobile App Development',
    url: '/services/mobile-app-development',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'mobile-development' }
    }
  },
  {
    id: 'ux/ui design',
    title: 'UX/UI Design',
    url: '/services/ux-ui',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'ux-ui' }
    }
  },
  {
    id: 'qa',
    title: 'QA Automation and Testing',
    url: '/services/qa-and-testing',
    link: {
      pathname: '/blog/category/[categorySlug]',
      query: { categorySlug: 'qa-automation-and-testing' }
    }
  }
];

export const industriesLinks = [
  {
    id: 'automotive',
    title: 'Automotive',
    url: '/industries/automotive',
    link: '/case-studies/category/automotive',
    seoDescription:
      'We have domain expertise and are ready to share the cases of partnership with the car businesses.'
  },
  {
    id: 'finance',
    title: 'Finance',
    url: '/industries/finance',
    link: '/case-studies/category/finance',
    seoDescription:
      'Our Fintech solutions have already helped many banking companies and financial businesses.'
  },
  {
    id: 'travel',
    title: 'Travel',
    url: '/industries/travel',
    link: '/case-studies/category/travel',
    seoDescription:
      'We develop Tourism Platforms, Hotel CRMs, Booking and Ticketing Software, and Trip Planning Portals.'
  },
  {
    id: 'health',
    title: 'Health',
    url: '/industries/health',
    link: '/case-studies/category/health',
    seoDescription:
      'Here you can find our most interesting health-related and wellness case studies.'
  },
  {
    id: 'government',
    title: 'Government',
    url: '/industries/government',
    link: '/case-studies/category/government',
    seoDescription:
      'Our software solutions help governmental organizations with management and reporting.'
  },
  {
    id: 'telecom',
    title: 'Telecom',
    url: '/industries/telecom',
    link: '/case-studies/category/telecom',
    seoDescription:
      'We build mobile and web apps, develop omnichannel platforms, use VoIP services and the ML algorithms.'
  },
  {
    id: 'media',
    title: 'Media & Entertainment',
    url: '/industries/media',
    link: '/case-studies/category/media',
    seoDescription:
      'We use our tech stack to architect advanced content, metadata, and media systems.'
  },
  {
    id: 'ecommerce',
    title: 'Retail & Ecommerce',
    url: '/industries/ecommerce',
    link: '/case-studies/category/ecommerce',
    seoDescription:
      'We build personalized customer journeys, collect and analyze the preferences of users.'
  },
  {
    id: 'logistics',
    title: 'Logistics & Transport',
    url: '/industries/logistics',
    link: '/case-studies/category/logistics',
    seoDescription: 'We develop custom logistics, shipping, and transportation software solutions.'
  }
];

export const aboutUsLinks = [
  {
    title: 'About UUUSoftware',
    url: '/company'
  },
  {
    title: 'Careers',
    url: '/careers'
  }
];

export const CASES_PER_PAGE = 7;
export const BLOGS_PER_PAGE = 9;
