import cx from 'classnames';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import { resolveHref } from 'next/dist/client/resolve-href';
import Link, { LinkProps } from 'next/link';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { FC, useMemo } from 'react';

type ActiveLinkProps = LinkProps & {
  activeClassName: typeof PropTypes.string.isRequired;
};

export const ActiveLink: FC<ActiveLinkProps> = (props) => {
  const { activeClassName, className, href, ...restProps } = props;
  const { asPath } = useRouter();

  const active = useMemo(() => {
    let active = false;
    if (isString(props.href) && asPath.split('#')[0] === props.href) active = true;
    if (isString(props.as) && asPath.split('#')[0] === props.as) active = true;
    if (isPlainObject(props.href)) {
      const [, resolvedAs] = resolveHref(Router, props.href, true);
      if (asPath.split('#')[0] === resolvedAs) active = true;
    }
    return active;
  }, [props.href, props.as, asPath]);

  return (
    <Link
      {...restProps}
      href={href}
      className={cx(className, {
        // @ts-ignore
        [activeClassName]: active
      })}
    />
  );
};

export default ActiveLink;
