import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import React, { createContext, useState, useEffect, useRef } from 'react';

export const ContactContext = createContext({
  isModalActive: false,
  toggleContactModal: function (): void {},
  isQueryString: false
});

export function useWindowSize() {
  type windowSize = {
    width: number | null;
    height: number | null;
  };
  const [windowSize, setWindowSize] = useState<windowSize>({
    width: null,
    height: null
  });
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 300);
    debouncedResizeHandler();
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []);
  return windowSize;
}

export const useOnScreen = (ref: React.RefObject<any>, playOnce = true) => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef<undefined | IntersectionObserver>(undefined);

  if (isOnScreen && playOnce) {
    observerRef.current?.disconnect();
  }

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting);
      },
      {
        threshold: 0.1
      }
    );
  }, []);

  useEffect(() => {
    observerRef.current?.observe(ref.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
};

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  return isMounted;
};

export const useHistory = () => {
  // TODO
  const { asPath: currentUrl } = useRouter();

  // const url = new URL(`https://uuusoftware.com${url}`);

  const pagesArray = currentUrl.split('#')[0].split('/').filter(Boolean);

  const routesWithoutIntro = [
    'blog',
    'contact-us',
    'case-studies',
    'industries',
    'careers',
    'thanks',
    'thanks-lm',
    'thanks-ams-demo',
    'privacy-policy',
    'info-pages'
  ];

  const rootPage = pagesArray[0];

  const isPageWithoutIntro = routesWithoutIntro.includes(rootPage);

  return {
    currentUrl,
    pagesArray,
    isPageWithoutIntro
  };
};

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);
};
