import React, { FC } from 'react';

type NoScriptPolyfillProps = {
  children: any;
};

export const NoScriptPolyfill: FC<NoScriptPolyfillProps> = (props) => {
  const { children } = props;
  return <noscript>{children}</noscript>;
};
