import cx from 'classnames';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';
import { useIsMounted } from '@/utils';
import { useBodyScrollLock } from '@/utils/useBodyScrollLock';

import './index.scss';

export const Modal = ({ active, onClose, children, className }) => {
  const isMounted = useIsMounted();

  const nodeRef = useRef(null);

  const scrollableNodeRef = useRef(null);

  useBodyScrollLock(scrollableNodeRef?.current, active);

  if (!isMounted) return null;

  return createPortal(
    <CSSTransition
      classNames="modal-wrapper"
      in={!!active}
      nodeRef={nodeRef}
      unmountOnExit
      timeout={400}
      appear
    >
      <div className={cx('modal', className)} onClick={onClose} ref={nodeRef}>
        <div
          className={'modal__content'}
          onClick={(e) => e.stopPropagation()}
          ref={scrollableNodeRef}
        >
          <button className="modal__close" onClick={onClose} aria-label="Close modal">
            <CloseIcon />
          </button>
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
};
