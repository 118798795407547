/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { useRef, useState } from 'react';

import { ReactComponent as SVGArrowDown } from '@/assets/svg/arrowDown.svg';
import ActiveLink from '@/components/ui/activeLink';

export const MobileSubmenu = ({
  toggleIsNavOpen,
  submenuTitle,
  submenuList = [],
  extraTitle,
  extraLink
}) => {
  const [isActive, setIsActive] = useState(false);
  const listRef = useRef(null);

  const handleLinkClick = () => {
    setIsActive(false);
    toggleIsNavOpen();
  };

  return (
    <li className="mobile-submenu">
      <span
        className="mobile-menu__item mobile-submenu__title"
        onClick={() => setIsActive((prevState) => !prevState)}
      >
        {submenuTitle}
        <span
          className={
            isActive
              ? 'mobile-submenu__arrow mobile-submenu__arrow--active'
              : 'mobile-submenu__arrow'
          }
        >
          <SVGArrowDown />
        </span>
      </span>
      {submenuList.length && (
        <ul
          className="mobile-submenu__list"
          ref={listRef}
          style={{
            maxHeight: isActive ? `${listRef.current && listRef.current.scrollHeight}px` : '0px'
          }}
        >
          {submenuList.map((link, index) => (
            <li className="mobile-menu__item mobile-submenu__item" key={`${link.title}|${index}`}>
              <ActiveLink
                href={link.url}
                className="mobile-menu__link mobile-submenu__link"
                activeClassName="mobile-menu__link--active"
                onClick={handleLinkClick}
              >
                {link.title}
              </ActiveLink>
            </li>
          ))}
          {extraTitle && extraLink && (
            <li className="mobile-menu__item mobile-submenu__item">
              <ActiveLink
                href={extraLink}
                className="mobile-menu__link mobile-menu__link--extra mobile-submenu__link"
                activeClassName="mobile-menu__link--active"
                onClick={handleLinkClick}
              >
                {extraTitle}
              </ActiveLink>
            </li>
          )}
        </ul>
      )}
    </li>
  );
};
