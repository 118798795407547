/* eslint-disable prefer-const */
/* eslint-disable react/no-unknown-property */
import { useMemo, useState, useEffect } from 'react';

// Leaving this in case announce needs to be turned on again.
// import { AnnounceBanner } from '../announceBanner';
import { Footer } from '../footer';
import { Header } from '../header';
import { ContactModal } from '@/components/contactModal';
import { ContactContext } from '@/utils';

export const Layout = ({ children }) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [isQueryString, setQueryString] = useState('');

  const toggleContactModal = useMemo(
    () => () => {
      setIsModalActive((prevValue) => !prevValue);
    },
    []
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search) {
      setQueryString(window.location.search);
    }
  }, []);

  const contextProps = useMemo(
    () => ({
      isModalActive,
      toggleContactModal,
      isQueryString
    }),
    [isModalActive, toggleContactModal, isQueryString]
  );

  return (
    <ContactContext.Provider value={contextProps}>
      {/* <AnnounceBanner/> */}
      <div className="page-border">
        <Header />
        {/* TODO Fix mobile menu and remove overflow hidden */}
        <main style={{ overflow: 'hidden' }}>{children}</main>
        <Footer />
        <ContactModal />
      </div>
    </ContactContext.Provider>
  );
};
