export const validateInputCharacters = (e) => {
  if (e.target.name === 'firstName' || e.target.name === 'lastName' || e.target.name === 'name') {
    const isNameValid = /^$|^[a-zA-Z\-'_\s]+$/.test(e.target.value);
    return isNameValid;
  }

  if (e.target.name === 'phone') {
    const isPhoneValid = /^[0-9_.()+-\s]*$/.test(e.target.value);
    return isPhoneValid;
  }

  if (e.target.name === 'email') {
    const isEmailValid = /^$|^[~`!@#$%^&*()_+=[\]\\{}|;':",./<>?a-zA-Z0-9-]+$/.test(e.target.value);
    return isEmailValid;
  }

  return true;
};

export const checkEmailAllowed = (email) => {
  const BANNED_MAIL = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'aol.com',
    'icloud.com',
    'mail.com',
    'gmx.com',
    'protonmail.com',
    'yandex.com',
    'zoho.com',
    'hushmail.com',
    'tutanota.com',
    'inbox.com',
    'fastmail.com',
    'lycos.com',
    'excite.com',
    'rediffmail.com',
    'mail.ru',
    'netcourrier.com'
  ];

  return !BANNED_MAIL.includes(email.split('@')[1]);
};
