import './index.scss';

export const Input = ({
  className,
  value,
  name,
  placeholder,
  type = 'text',
  onChange,
  maxLength = 100,
  inputError,
  setInputRef
}) => {
  let inputClass = 'input-wrapper';
  if (className) {
    inputClass += ` ${className}`;
  }
  if (inputError) {
    inputClass += ' input-wrapper--error';
  }

  return (
    <div className={inputClass}>
      <input
        className="input"
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        ref={setInputRef ? (el) => setInputRef(el) : undefined}
      />
      {inputError && <span className="input__error">{inputError}</span>}
    </div>
  );
};
