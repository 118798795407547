import './index.scss';

export const Burger = ({ burgerActive, toggleBurgerMenu }) => (
  <button
    className={burgerActive ? 'burger burger--active' : 'burger'}
    onClick={toggleBurgerMenu}
    aria-expanded={burgerActive}
    aria-label="Burger Menu"
  >
    <span className="burger__line burger__line--1" />
    <span className="burger__line burger__line--2" />
    <span className="burger__line burger__line--3" />
  </button>
);
