import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useMeetingHash } from '../meetingModal';
import { Button } from '@/components/ui/button';
import { useHistory } from '@/utils';
import { NoScriptPolyfill } from '@/utils/noScriptPolyfill';

export const ContactBtns = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { asPath: currentUrl } = useRouter();

  const meetingHash = useMeetingHash();

  const meetingUrl = `${currentUrl.split('#')[0]}${meetingHash}`;

  // TODO update logic to avoid window.location.reload();
  const openMeetingModal = () => {
    if (window.location.hash) {
      window.location.reload();
    }
  };

  useEffect(() => {
    setIsTooltipOpen(false);
  }, [currentUrl]);

  return (
    <li
      className="nav__item"
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
      data-drop-down-container
    >
      <NoScriptPolyfill>
        <style>
          {`
          [data-drop-down-container]:hover [data-drop-down-content] {
            display: block !important;
          }`}
        </style>
      </NoScriptPolyfill>
      <Button className="nav__btn" text="Contact us" withArrow={false} />
      <div
        className="nav__btns"
        id="contact-us__popup"
        style={{ display: isTooltipOpen ? 'block' : 'none' }}
        data-drop-down-content
      >
        <div className="nav__btns-inner">
          <div className="nav__btns-item">
            <Button
              className="nav__btn"
              text="Schedule a Meeting"
              isLink
              onClick={openMeetingModal}
              href={meetingUrl}
            />
            <p className="nav__btns-text">
              Want to share your ideas but prefer a personal meeting or video conference? No
              problem. Just choose a convenient day and time, and we’ll be waiting for you.
            </p>
          </div>
          <div className="nav__btns-item">
            <Button className="nav__btn" text="Get in touch" isLink href="/contact-us" />
            <p className="nav__btns-text">
              Share the details of your project and our tech specialists will contact you to suggest
              a solution as soon as possible. Your idea is valuable to us.
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};
