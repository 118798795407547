import './index.scss';

export const Textarea = ({ className, value, name, placeholder, onChange, maxLength = 300 }) => (
  <textarea
    className={className ? `${className} textarea` : 'textarea'}
    value={value}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    maxLength={maxLength}
  />
);
