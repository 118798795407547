import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

import { PRERENDER_DATA_FILE_NAME } from './constants';
import { PrerenderDataContext } from './context';
import { normalizeUrl, getPrerenderdata, checkProps } from './utils';

function usePrerenderData(props, doAutomaticFetch = true) {
  return [props];
  /*
  console.log('usePrerenderData', props, doAutomaticFetch);
  let value;
  const [state, setState] = useState({
    value,
    isLoading: false,
    error: false
  });
  const contextValue = useContext(PrerenderDataContext);

  const { asPath } = useRouter();

  checkProps({...props, url: asPath});

  async function fetchPreRenderData() {
    setState({
      value: null,
      isLoading: true,
      error: false
    });
    try {
      const response = await fetch(
        `${normalizeUrl(asPath)}${PRERENDER_DATA_FILE_NAME}`
      );
      const json = await response.json();
      setState({
        value: json,
        isLoading: false,
        error: false
      });
    } catch (e) {
      setState({
        value: null,
        isLoading: false,
        error: e
      });
    }
  }

  if (contextValue.CLI_DATA && contextValue.CLI_DATA.preRenderData) {
    value = contextValue.CLI_DATA.preRenderData;
  }

  const data = getPrerenderdata(state.value || value, props);
  if (doAutomaticFetch && !data && !state.isLoading && !state.error) {
    // TODO
    // fetchPreRenderData();
    return [data, true, state.error];
  }
  return [data, state.isLoading, state.error]; */
}

export { usePrerenderData };
