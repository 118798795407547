import Link from 'next/link';
import { useEffect, useState } from 'react';

import ActiveLink from '@/components/ui/activeLink';
import { useHistory } from '@/utils';

export const NavSubmenu = ({ linksList, baseTitle, baseLink }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { currentUrl } = useHistory();

  useEffect(() => {
    setIsTooltipOpen(false);
  }, [currentUrl]);

  return (
    <li
      className="nav__item"
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      {baseLink ? (
        <ActiveLink href={baseLink} className="nav__link" activeClassName="nav__link--active">
          {baseTitle}
        </ActiveLink>
      ) : (
        <span className="nav__link">{baseTitle}</span>
      )}
      {isTooltipOpen && (
        <ul className="nav__submenu">
          {linksList.map((link) => (
            <li className="nav__submenu-item" key={link.url}>
              <ActiveLink
                href={link.url}
                className="nav__submenu-link"
                activeClassName="nav__link--active"
              >
                {link.title}
              </ActiveLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
