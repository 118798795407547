import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useLayoutEffect, useState } from 'react';

import './useBodyScrollLock.scss';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function useBodyScrollLock(targetElement: Element, locked: boolean = false) {
  const [wasLocked, storeLockedInHistory] = useState(locked);

  function releaseScrollLock() {
    enableBodyScroll(targetElement);
    storeLockedInHistory(false);
  }

  // Do the side effect before render
  useIsomorphicLayoutEffect(() => {
    if (locked) {
      disableBodyScroll(targetElement, { reserveScrollBarGap: true });
      storeLockedInHistory(true);
    } else if (wasLocked) {
      releaseScrollLock();
    }

    return () => {
      if (wasLocked) releaseScrollLock();
    };
  }, [locked, targetElement]);
}
