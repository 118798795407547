import Link, { LinkProps as _LinkProps } from 'next/link';
import { useMemo, FC } from 'react';

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow.svg';

import './index.scss';

type ButtonProps = {
  isLink?: false;
  text: string;
  className?: string;
  type?: string;
  onClick?: Function;
  disabled?: boolean;
  isColored?: boolean;
  withHoverGradient?: boolean;
  withArrow?: boolean;
  style?: object;
};

type LinkProps = {
  isLink: true;
  text: string;
  className?: string;
  onClick?: Function;
  href: _LinkProps['href'];
};

export const Button: FC<ButtonProps | LinkProps> = (props) => {
  const {
    text,
    className,
    type = 'button',
    onClick,
    disabled,
    isColored,
    withHoverGradient = true,
    withArrow = true,
    isLink = false,
    href,
    style
  } = props as ButtonProps & LinkProps;

  const generateClass = useMemo(() => {
    let btnClass = 'btn';

    if (className) {
      btnClass += ` ${className}`;
    }

    if (withHoverGradient) {
      btnClass += ' btn--gradient';
    }

    if (isColored) {
      btnClass += ' btn--colored';
    }

    return btnClass;
  }, [className, isColored, withHoverGradient]);

  return (
    <>
      {!isLink ? (
        <button
          className={generateClass}
          // @ts-ignore
          onClick={onClick}
          disabled={disabled}
          // @ts-ignore
          type={type}
          style={style}
        >
          {text} {withArrow && <ArrowIcon />}
        </button>
      ) : (
        <Link
          className={generateClass}
          href={href}
          // @ts-ignore
          onClick={onClick}
        >
          {text} {withArrow && <ArrowIcon />}
        </Link>
      )}
    </>
  );
};
