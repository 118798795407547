import {
  WithContext,
  Organization,
  ContactPoint,
  PostalAddress,
  Blog,
  BlogPosting,
  Thing
} from 'schema-dts';

import { MAIN_DOMAIN_URL } from '@/env';

export function makeRoot<T extends Thing>(schema: any): WithContext<T> {
  return {
    '@context': 'https://schema.org',
    ...schema
  };
}

export const getOrganizationSchema = (): Organization => ({
  // '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'UUUSoftware.com',
  description:
    'We help clients build tailored digital solutions and port them to the cloud. We assemble dedicated teams of developers, DevOps engineers, and UX/UI specialists.',
  logo: `${MAIN_DOMAIN_URL}/assets/images/uuusoftware-logo.png`,
  url: `${MAIN_DOMAIN_URL}`,
  sameAs: [
    'https://www.facebook.com/uuusoftware',
    'https://x.com/uuusoftware',
    'https://www.linkedin.com/company/uuusoftware'
  ],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+16478946457',
    contactType: 'Customer Service'
  },
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Unit 638, 121 Richmond St W',
    addressLocality: 'Toronto',
    postalCode: 'M5H 2K1',
    addressCountry: 'CA'
  }
});

export const getBlogPageSchema = (posts: BlogPosting[]): WithContext<Blog> => ({
  '@context': 'https://schema.org',
  '@type': 'Blog',
  name: 'UUUSoftware Blog',
  url: `${MAIN_DOMAIN_URL}/blog`,
  blogPost: posts
});
