import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Fragment } from 'react';
import { WebPage, WithContext } from 'schema-dts';

import { MAIN_DOMAIN_URL, CDN_URL } from '@/env';
import { getOrganizationSchema, makeRoot } from '@/schema';

interface Props {
  seo?: { title?: string; description?: string; cover?: string };
  webpageMainEntitySchema?: WebPage['mainEntity'];
  excludeFromSitemap?: any;
}

export const MetaHelper = (props: Props) => {
  const { asPath } = useRouter();

  // TODO props keywords: []

  const canonicalUrl = `${MAIN_DOMAIN_URL}${asPath}`;
  const title =
    props?.seo?.title || 'UUUSoftware is a Canadian custom software development company';
  const description =
    props?.seo?.description ||
    'We support our clients by building digital solutions and porting them to the cloud. We assemble dedicated teams of developers, DevOps engineers, and UX/UI specialists.';

  // const coverImg = (() => {
  let coverImg = props?.seo?.cover;
  if (!coverImg) coverImg = `${MAIN_DOMAIN_URL}/assets/images/preview.png`;
  const isRelativeUrl = !coverImg.startsWith('http') && !coverImg.startsWith('//');
  if (coverImg && isRelativeUrl && CDN_URL) coverImg = CDN_URL + coverImg;
  // return coverImg;
  // })();

  const organizationSchema = getOrganizationSchema();
  const rootOrganizationSchema = makeRoot(organizationSchema);

  const rootWebpageSchema: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: canonicalUrl,
    // TODO breadcrumb
    name: title,
    mainEntity: props.webpageMainEntitySchema || organizationSchema,
    // TODO keywords
    primaryImageOfPage: {
      '@type': 'ImageObject',
      contentUrl: coverImg,
      url: coverImg,
      description: description
    }
  };

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        <link rel="icon" href={require('@/assets/favicon.ico').default.src} />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={coverImg} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="UUUSoftware" />
        <meta name="twitter:site" content="@uuusoftware" />
        <meta name="twitter:creator" content="@uuusoftware" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={coverImg} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {props?.excludeFromSitemap && <meta name="robots" content="noindex, nofollow" />}
      </Head>
      <Script type="application/ld+json" id="schema.org/organization">
        {JSON.stringify(rootOrganizationSchema)}
      </Script>
      <Script type="application/ld+json" id="schema.org/webpage">
        {JSON.stringify(rootWebpageSchema)}
      </Script>
    </Fragment>
  );
};
