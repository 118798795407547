// eslint-disable-next-line import/order
import '@/style/index.scss'; // !IMPORTANT! This should be before any project files

// Imported for fixing CSS Conflicting order
// eslint-disable-next-line import/no-unresolved,import/order
import 'swiper/css/bundle';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import font4 from '@/assets/fonts/raleway-v27-latin-700.woff';
import font3 from '@/assets/fonts/raleway-v27-latin-700.woff2?base64';
import font2 from '@/assets/fonts/raleway-v27-latin-regular.woff';
import font1 from '@/assets/fonts/raleway-v27-latin-regular.woff2?base64';
import { Layout } from '@/components/ui/layout';
import { MetaHelper } from '@/utils/metaHelper';
import { Provider } from '@/utils/prerender-data-provider';

if (typeof window !== 'undefined' && window.origin === 'https://uuusoftware.com') {
  // @ts-ignore
  import('@/external-services');
}

// Import all assets
// eslint-disable-next-line no-unused-expressions
() =>
  import(
    /* webpackChunkName: 'assets' */
    /* webpackMode: "weak" */
    '@/assets'
  );

// {/* IMPORTANT! For style[data-critical-css-bypass] */}
// <!-- IMPORTANT! For style[data-critical-css-bypass] */ -->

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider value={pageProps}>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: `
        /* raleway-regular - latin */
        @font-face {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        f1ont-display: swap;
        font-display: block;
        src: local('Raleway Regular'),
        url('${font1}') format('woff2'),
        url('${font2}') format('woff');
      }
        /* raleway-700 - latin */
        @font-face {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-display: block;
        f1ont-display: swap;
        src: local('Raleway Bold'),
        url('${font3}') format('woff2'),
        url('${font4}') format('woff');
      }
      `
          }}
        />
        <noscript>
          <style
            dangerouslySetInnerHTML={{
              __html: `
.noScriptVisibilityHidden {
  visibility: hidden !important;
}
.noScriptDisplayNone {
  display: none !important;
}`
            }}
          />
        </noscript>
      </Head>
      <MetaHelper {...pageProps} />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Provider>
  );
}
