import { useRouter } from 'next/router';
import { useContext, useRef, useState } from 'react';

import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Modal } from '../ui/modal';
import { Textarea } from '../ui/textarea';
import { ReactComponent as SVGContactUs } from '@/assets/svg/contactUs.svg';
import { ContactContext } from '@/utils';
import { formsBaseUrl, legalConsentOptions } from '@/utils/constants';
import { validateInputCharacters } from '@/utils/validation';

import './index.scss';

const formUrl = '059d02df-6a4f-4fc6-b03e-4ec673ea6795';

export const ContactModal = () => {
  const router = useRouter();
  const currentUrl = router.asPath;
  const { isModalActive, toggleContactModal, isQueryString } = useContext(ContactContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    budget: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const formRef = useRef(null);

  const handleChangeInput = (e) => {
    if (e.target.name === 'name' || e.target.name === 'email' || e.target.name === 'phone') {
      setFormErrors({
        ...formErrors,
        [e.target.name]: ''
      });
    }

    if (validateInputCharacters(e)) setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateSubmit = () => {
    let errors = {
      name: '',
      phone: '',
      email: ''
    };
    let isValid = true;
    const { name, phone, email } = formData;

    if (!name || name.length < 2) {
      errors = {
        ...errors,
        name: name ? 'Min length is 2 symbols' : 'Please enter your name'
      };
      isValid = false;
    }

    if (!email || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors = {
        ...errors,
        email: 'Please enter a valid email'
      };
      isValid = false;
    }

    if (phone && !phone.match(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)) {
      errors = {
        ...errors,
        phone: 'Please enter a valid phone'
      };
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) return false;

    fetch(`${formsBaseUrl}/${formUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields: [
          {
            name: 'email',
            value: formData.email
          },
          {
            name: 'firstname',
            value: formData.name
          },
          {
            name: 'phone',
            value: formData.phone
          },
          {
            name: 'message',
            value: formData.message
          },
          {
            name: 'pre_estimated_budget',
            value: formData.budget
          },
          {
            name: 'conversion_url',
            value: currentUrl
          },
          {
            name: 'conversion_parameters',
            value: isQueryString
          }
        ],
        legalConsentOptions
      })
    })
      .then(() => {
        toggleContactModal();
        router.replace('/thanks');
      })
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert('Something went wrong. Please try again later.');
        // eslint-disable-next-line no-console
        console.log(error);
      })
      .finally(() => {
        setFormData({
          name: '',
          email: '',
          phone: '',
          budget: '',
          message: ''
        });
      });
  };

  return (
    <Modal active={isModalActive} onClose={toggleContactModal}>
      <section className="container contactus" ref={formRef}>
        <i className="contactus__modalBackground" />
        <h2 className="section-title">Contact us</h2>
        <form
          id="contact_us_modal"
          className="contactus__form"
          onSubmit={handleSubmit}
          name="contact_us_modal"
        >
          <div className="contactus__row">
            <Input
              className="contactus__input"
              value={formData.name}
              name="name"
              placeholder="Your name"
              onChange={handleChangeInput}
              inputError={formErrors.name}
            />
            <Input
              className="contactus__input"
              value={formData.email}
              name="email"
              placeholder="Email"
              onChange={handleChangeInput}
              inputError={formErrors.email}
            />
          </div>
          <div className="contactus__row">
            <Input
              className="contactus__input"
              value={formData.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChangeInput}
              maxLength={30}
              inputError={formErrors.phone}
            />
            <select
              className="contactus__select"
              name="budget"
              aria-required="true"
              aria-invalid="false"
              value={formData.budget}
              onChange={handleChangeInput}
            >
              <option value="" disabled selected>
                Pre-estimated budget
              </option>
              <option value="10,000$">10,000$</option>
              <option value="50,000$">50,000$</option>
              <option value="100,000$">100,000$</option>
              <option value="200,000$">200,000$</option>
              <option value="400,000$">400,000$</option>
            </select>
          </div>
          <Textarea
            className="contactus__textarea"
            value={formData.message}
            name="message"
            placeholder="Type your message"
            onChange={handleChangeInput}
          />
          <Button
            className="contactus__btn"
            text="Send"
            withHoverGradient={false}
            isColored
            type="submit"
          />
        </form>
        <SVGContactUs />
      </section>
    </Modal>
  );
};
