/* eslint-disable jsx-a11y/control-has-associated-label */
import Link from 'next/link';

import { ReactComponent as SVGEmail } from '@/assets/svg/email.svg';
import { ReactComponent as SVGFacebook } from '@/assets/svg/facebook.svg';
import { ReactComponent as SVGLinkedin } from '@/assets/svg/linkedin.svg';
import { ReactComponent as SVGLogo } from '@/assets/svg/logo.svg';
import { ReactComponent as SVGPhone } from '@/assets/svg/phone.svg';
import { ReactComponent as SVGPin } from '@/assets/svg/pin.svg';
import { ReactComponent as SVGX } from '@/assets/svg/x.svg';
import { servicesLinks } from '@/utils/staticData';

import './index.scss';

export const Footer = () => (
  <footer className="footer">
    <div className="footer__inner container">
      <div className="footer__top">
        <Link className="footer__logo" href="/" aria-label="UUUSoftware">
          <SVGLogo />
        </Link>
        <a href="tel:+16478946457" className="footer__phone">
          <SVGPhone />
          +1 647 894 6457
        </a>
        <a
          href="https://maps.app.goo.gl/BSAgj8cVqTiE6vFt9"
          target="_blank"
          rel="nofollow noreferrer"
          className="footer__address"
        >
          <SVGPin />
          Unit 638, 121 Richmond St W, Toronto, Ontario, M5H 2K1, Canada
        </a>
      </div>
      <div className="footer__content">
        <div className="footer__contact">
          <div className="footer__contact-info">
            <h3 className="footer__title">About Us</h3>
            <p className="mb-2">
              UUUSoftware is a Canadian company led by Ph.D. engineers. We assemble teams of senior
              software developers, cloud DevOps engineers, and UX/UI experts ⎯ both in Canada and
              offshore. Solid experience in the latest technologies, proven expertise and 150+
              projects delivered enable us to say that we can turn every idea into reality.
            </p>
          </div>
          <div className="footer__social">
            <a
              href="https://www.facebook.com/uuusoftware"
              className="footer__social-link"
              aria-label="Facebook"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <SVGFacebook />
            </a>
            <a
              href="https://x.com/uuusoftware"
              className="footer__social-link"
              aria-label="X (formerly Twitter)"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <SVGX />
            </a>
            <a
              href="https://www.linkedin.com/company/uuusoftware-com"
              className="footer__social-link"
              aria-label="linkedin"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <SVGLinkedin />
            </a>
            <a
              href="mailto:contact@uuusoftware.com"
              className="footer__social-link"
              aria-label="email"
            >
              <SVGEmail />
            </a>
          </div>
        </div>
        <div className="footer__menu">
          <h3 className="footer__title">Services</h3>
          <ul className="footer__menu-list">
            {servicesLinks.map((link, index) => (
              <li className="footer__menu-item" key={`i${index}`}>
                <Link className="footer__menu-link" href={link.url}>
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer__menu">
          <h3 className="footer__title">Quick Links</h3>
          <ul className="footer__menu-list">
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/company">
                Company
              </Link>
            </li>
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/services">
                Services
              </Link>
            </li>
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/industries">
                Industries
              </Link>
            </li>
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/case-studies">
                Case studies
              </Link>
            </li>
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/careers">
                Careers
              </Link>
            </li>
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/blog">
                Blog
              </Link>
            </li>
            <li className="footer__menu-item">
              <Link className="footer__menu-link" href="/contact-us">
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__copy">
        <p>Copyright © UUUSoftware.com. All rights reserved. 2007-{new Date().getFullYear()}</p>
        <Link className="footer__menu-link" href="/privacy-policy">
          Privacy policy
        </Link>
      </div>
    </div>
    <div className="footer__animation">
      <div className="footer__animation-wave" />
      <div className="footer__animation-wave" />
    </div>
  </footer>
);
