export const formsBaseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/21833432';

export const legalConsentOptions = {
  consent: {
    consentToProcess: true,
    text: 'I agree to allow UUUSoftware.com to store and process my personal data.',
    communications: [
      {
        value: true,
        subscriptionTypeId: 999,
        text: 'I agree to receive marketing communications from UUUSoftware.com.'
      }
    ]
  }
};
