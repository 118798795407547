import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

import { Modal } from '@/components/ui/modal';
import { meetingCalendarPersons } from '@/utils/staticData';

import './index.scss';

export const useMeetingHash = () => {
  // TODO update logic to avoid useState
  const { asPath } = useRouter();

  const [meetingHash, setMeetingHash] = useState(Object.keys(meetingCalendarPersons)[0]);
  const currentMeetingHash = asPath.split('#')[1] ? '#' + asPath.split('#')[1] : '';
  if (currentMeetingHash !== meetingHash && meetingCalendarPersons[currentMeetingHash]) {
    setMeetingHash(currentMeetingHash);
  }

  return meetingHash;
};

export const MeetingModal = () => {
  const [isMeetingModalActive, setIsMeetingModalActive] = useState(false);

  const router = useRouter();
  const currentHash = router.asPath.split('#')[1] ? '#' + router.asPath.split('#')[1] : '';

  useEffect(() => {
    if (meetingCalendarPersons[currentHash] && !isMeetingModalActive) {
      setIsMeetingModalActive(true);
    }
    if (!meetingCalendarPersons[currentHash] && isMeetingModalActive) {
      setIsMeetingModalActive(false);
    }
  }, [currentHash, isMeetingModalActive]);

  // TODO Refactor this code and logic
  const meetingLink =
    meetingCalendarPersons[currentHash] || Object.values(meetingCalendarPersons)[0];

  return (
    <Modal
      active={isMeetingModalActive && meetingLink}
      onClose={() => router.replace(router.asPath.split('#')[0])}
      className="meeting-modal"
    >
      <div className="meeting-modal__inner">
        {isMeetingModalActive && (
          <iframe
            className="meeting-modal__calendar"
            title="hubspot"
            src={`${meetingLink}/?embed=true`}
          />
        )}
      </div>
    </Modal>
  );
};
