import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { MobileSubmenu } from './mobileSubmenu';
import { useMeetingHash } from '../meetingModal';
import { ReactComponent as SVGEmail } from '@/assets/svg/email.svg';
import { ReactComponent as SVGFacebook } from '@/assets/svg/facebook.svg';
import { ReactComponent as SVGLinkedin } from '@/assets/svg/linkedin.svg';
import { ReactComponent as LogoIcon } from '@/assets/svg/logo-with-fill-current-color.svg';
import { ReactComponent as SVGPhone } from '@/assets/svg/phone.svg';
import { ReactComponent as SVGPin } from '@/assets/svg/pin.svg';
import { ReactComponent as SVGX } from '@/assets/svg/x.svg';
import ActiveLink from '@/components/ui/activeLink';
import { useBodyScrollLock } from '@/utils/useBodyScrollLock';

import './index.scss';

export const MobileMenu = ({ isOpen, toggleIsOpen, aboutUsLinks, servicesLinks }) => {
  const { asPath: currentUrl } = useRouter();
  const meetingHash = useMeetingHash();
  const meetingUrl = `${currentUrl.split('#')[0]}${meetingHash}`;

  // TODO update logic to avoid window.location.reload();
  const openMeetingModal = () => {
    toggleIsOpen();
    if (window.location.hash) {
      window.location.reload();
    }
  };

  const nodeRef = useRef(null);

  const scrollableNodeRef = useRef(null);

  useBodyScrollLock(scrollableNodeRef?.current, isOpen);

  return (
    <div
      className={cx('mobile-menu-container', {
        'mobile-menu-container_menuOpened': !!isOpen
      })}
      ref={scrollableNodeRef}
    >
      <CSSTransition classNames="mobile-menu" in={!!isOpen} nodeRef={nodeRef} timeout={500} appear>
        <div className={cx('mobile-menu')} ref={nodeRef}>
          <div className="container relative mobile-menu__logoContainer">
            <Link
              className="mobile-menu__logoLink"
              href="/"
              onClick={toggleIsOpen}
              aria-label="UUUSoftware"
            >
              <LogoIcon />
            </Link>
          </div>
          <div className="mobile-menu__inner container">
            <nav className="mobile-menu__nav">
              <ul className="mobile-menu__list">
                <MobileSubmenu
                  toggleIsNavOpen={toggleIsOpen}
                  submenuTitle="Company"
                  submenuList={aboutUsLinks}
                />
                <MobileSubmenu
                  toggleIsNavOpen={toggleIsOpen}
                  submenuTitle="Services"
                  submenuList={servicesLinks}
                  extraTitle="All services"
                  extraLink="/services"
                />
                <li className="mobile-menu__item">
                  <ActiveLink
                    href="/industries"
                    className="mobile-menu__link"
                    activeClassName="mobile-menu__link--active"
                    onClick={toggleIsOpen}
                  >
                    Industries
                  </ActiveLink>
                </li>
                <li className="mobile-menu__item">
                  <ActiveLink
                    href="/case-studies"
                    className="mobile-menu__link"
                    activeClassName="mobile-menu__link--active"
                    onClick={toggleIsOpen}
                  >
                    Case studies
                  </ActiveLink>
                </li>
                <li className="mobile-menu__item">
                  <ActiveLink
                    href="/blog"
                    className="mobile-menu__link"
                    activeClassName="mobile-menu__link--active"
                    onClick={toggleIsOpen}
                  >
                    Blog
                  </ActiveLink>
                </li>
                <li className="mobile-menu__item">
                  <ActiveLink
                    href="/contact-us"
                    className="mobile-menu__link"
                    activeClassName="mobile-menu__link--active"
                    onClick={toggleIsOpen}
                  >
                    Contact
                  </ActiveLink>
                </li>
                <li className="mobile-menu__item">
                  <ActiveLink
                    href={meetingUrl}
                    className="mobile-menu__link"
                    activeClassName="mobile-menu__link--active"
                    onClick={openMeetingModal}
                  >
                    Schedule a Meeting
                  </ActiveLink>
                </li>
              </ul>
            </nav>
            <div className="mobile-menu__footer">
              <a className="mobile-menu__footer-link" href="tel:+16478946457">
                <SVGPhone />
                +1 647 894 6457
              </a>
              <a
                className="mobile-menu__footer-link"
                href="https://maps.app.goo.gl/BSAgj8cVqTiE6vFt9"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <SVGPin />
                121 Richmond st. West, unit 638, ON, M5H 2K1, Canada
              </a>
              <div className="mobile-menu__footer-social">
                <a
                  className="footer-link"
                  href="https://www.facebook.com/uuusoftware"
                  aria-label="Facebook"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <SVGFacebook />
                </a>
                <a
                  className="footer-link"
                  href="https://x.com/uuusoftware"
                  aria-label="X (formerly Twitter)"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <SVGX />
                </a>
                <a
                  className="footer-link"
                  href="https://www.linkedin.com/company/uuusoftware-com"
                  aria-label="Linkedin"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <SVGLinkedin />
                </a>
                <a className="footer-link" href="mailto:contact@uuusoftware.com" aria-label="Email">
                  <SVGEmail />
                </a>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
